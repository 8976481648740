<template>
  <div class="auth-layout-wrap isaloginbck" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
      <div class="container">
        <div class="d-flex justify-content-center h-100">
          <div class="card">
            <ISALogin/>            
          </div>          
        </div>
      </div>    
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ISALogin from "@/components/ISA/session/ISALogin";
import { IMGService } from '@/services/img.service'
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "SignIn"
  },
  components: {
    ISALogin,
  },
  data() {
    return {
      email: "ui-lib@gmail.com",
      password: "123456",
      // // password: "vue006",
      userId: "",
      bgImage: IMGService.bgImage,
      logo: IMGService.isalogo,     
    };
  },
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13;
    },
    ...mapGetters([ "loading"])
  },

  methods: {
    ...mapActions(["login"]),
    formSubmit() {
      this.login({ email: this.email, password: this.password });
    },
    makeToast(variant = null, msg) {
      this.$bvToast.toast(msg, {
        title: ` ${variant || "default"}`,
        variant: variant,
        solid: true
      });
    },
    removeReadonly(e) {
      e.target.removeAttribute('readonly');                
    },
  },
  watch: {
    loggedInUser(val) {
      if (val && val.uid && val.uid.length > 0) {
        this.makeToast("success", "Successfully Logged In");

        setTimeout(() => {
          this.$router.push("/");
        }, 500);
      }
    },
    error(val) {
      if (val != null) {
        this.makeToast("warning", val.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.spinner.sm {
  height: 2em;
  width: 2em;
}

.auth-layout-wrap {    
    align-items: center;
    
}

@media screen and (max-width: 640px) {
    .auth-layout-wrap {
        justify-content: unset;
    }
}

.auth-layout-wrap .auth-content {
    max-width: 400px;
    margin: 5px;
}

.container{
  height: 100%;
  align-content: center;
}

.card{
  height: 385px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(236, 234, 234, 0.397) !important;
}

.isaloginbck {   
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  
  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;
}

.password {
    -webkit-text-security:disc;
    font-family: text-security-disc;
}
</style>
